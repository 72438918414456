
<template>
  <div id="app">
    <div class="sudoku_board">
      <table style="border-collapse:separate; border-spacing:0px 0px;">
        <tr class="sudoku_row" v-for="n in 9" :key="n">
          <td
            :class="tdClass(i + n * 9 - 9)"
            v-for="(cell, i) in sudokus.slice(n * 9 - 9, n * 9)"
            :key="i"
            @click="touchstart(i)"
          >
            <input
              type="text"
              class="sudoku_cell"
              onkeydown="this.value=''"
              @keyup="number(i + n * 9 - 9, $event)"
              v-model="sudokus[i + n * 9 - 9]"
            />
          </td>
        </tr>
      </table>
    </div>
    <div><button @click="resolve">submit</button><button @click="reset">reset</button></div>
  </div>
</template>  
<script>
import axios from 'axios';
export default {
  name: "sudoku",
  data() {
    return {
      sudokus: [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      rowcount: 9,
      columncount: 9,
      result: false,
      curSelect: null,
    };
  },
  components: {},
  computed: {
    tdClass() {
      return function (n) {
        let tdclass = "";

        let topleft = [0, 3, 6, 27, 30, 33, 54, 57, 60];
        let top = [1, 4, 7, 28, 31, 34, 55, 58, 61];
        let topright = [2, 5, 8, 29, 32, 35, 56, 59, 62];
        let left = [9, 12, 15, 36, 39, 42, 63, 66, 69];
        let none = [10, 13, 16, 37, 40, 43, 64, 67, 70];
        let right = [11, 14, 17, 38, 41, 44, 65, 68, 71];
        let bottomleft = [18, 21, 24, 45, 48, 51, 72, 75, 78];
        let bottom = [19, 22, 25, 46, 49, 52, 73, 76, 79];
        let bottomright = [20, 23, 26, 47, 50, 53, 74, 77, 80];
        if (topleft.indexOf(n) > -1) {
          return tdclass + "topleft";
        }
        if (top.indexOf(n) > -1) {
          return tdclass + "top";
        }
        if (topright.indexOf(n) > -1) {
          return tdclass + "topright";
        }
        if (left.indexOf(n) > -1) {
          return tdclass + "left";
        }
        if (none.indexOf(n) > -1) {
          return tdclass + "none";
        }
        if (right.indexOf(n) > -1) {
          return tdclass + "right";
        }
        if (bottomleft.indexOf(n) > -1) {
          return tdclass + "bottomleft";
        }
        if (bottom.indexOf(n) > -1) {
          return tdclass + "bottom";
        }
        if (bottomright.indexOf(n) > -1) {
          return tdclass + "bottomright";
        }
      };
    },
  },
  methods: {
    reset: function() {
      for(let i=0,len=this.sudokus.length;i<len;i++){
        this.$set(this.sudokus,i,'');
      }
      
    },
    resolve: function(){
     var that=this;
     var sudo=new axios({
        url: '/sudoku/resolve',
        method: 'post',
        data: this.sudokus
      });
      sudo.then(function(res){
        console.log(res.data.data.answer);
        that.sudokus=res.data.data.answer;
      })
    },
    number: function (n, event) {
      if (this.sudokus[n] == "0") {
        this.sudokus[n] = "";
      } else if (this.sudokus[n].length != 1) {
        this.sudokus[n] = this.sudokus[n].replace(/[^1-9]/g, "").substr(0, 1);
      } else {
        this.sudokus[n] = this.sudokus[n].replace(/\D/g, "");
      }
      event.currentTarget.value = this.sudokus[n];
    },
    touchstart: function (e) {
      var that = this;
      var list = that.sudokus;
      for (var i = 0, len = list.length; i < len; ++i) {
        if (list[i].index == e) {
          that.curSelect = i;
        }
      }
    },
    touchend: function () {
      var that = this;
      that.curSelect = null;
    },
  },
};
</script>  
<style >
.sudoku_cell {
  border-width: 1px;
  display: inline-block;
  width: 25px;
  height: 25px;
  align-content: center;
  text-align: center;
}
.sudoku_board {
  /* display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; */
}
.sudoku_row {
  /* display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; */
}
td{
  display: inline-block;
  border: 1px solid #999;
}
.none {
  border-bottom-style: none;
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}
.top {
  border-bottom-style: none;
  /* border-top-style:none; */
  border-left-style: none;
  border-right-style: none;
}
.bottom {
  /* border-bottom-style:none; */
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
}
.left {
  border-bottom-style: none;
  border-top-style: none;
  /* border-left-style:none; */
  border-right-style: none;
}
.right {
  border-bottom-style: none;
  border-top-style: none;
  border-left-style: none;
  /* border-right-style:none; */
}
.topleft {
  border-bottom-style: none;
  /* border-top-style:none; */
  /* border-left-style:none; */
  border-right-style: none;
}
.bottomleft {
  /* border-bottom-style:none; */
  border-top-style: none;
  /* border-left-style:none; */
  border-right-style: none;
}
.topright {
  border-bottom-style: none;
  /* border-top-style:none; */
  border-left-style: none;
  /* border-right-style:none; */
}
.bottomright {
  /* border-bottom-style:none; */
  border-top-style: none;
  border-left-style: none;
  /* border-right-style:none; */
}
.opacity {
  opacity: 0.4;
  background: #e5e5e5;
}
</style> 